.Notifications__container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.5rem 1rem;
}
.Notifications__container > * {
  position: relative !important;
}
.Notifications__searchBar {
  display: flex;
  flex-direction: row;
  margin: 0 -0.5rem;
}
.Notifications__searchBar > * {
  padding: 0 0.5rem;
}
.Notifications__searchBar > .Notifications__dateField {
  flex: 0 0 20%;
}
.Notifications__searchBar > .Notifications__queryField {
  flex: 1 0 auto;
}
.Notifications__searchBar > .Notifications__limitField {
  flex: 0 0 10%;
}
.Notifications__searchBar > .Notifications__submitField label {
  display: block !important;
}